import { Ajax } from "./ajax";
import { User, Client, Remote, PatchableUser } from "../definitions";

class API {
    private readonly ajax = new Ajax();
    private readonly url = process.env.NODE_ENV === "development" ?
        "http://127.0.0.1:8080/api/v1" :
        `https://${location.host}/api/v1`;


    setStunToken(token: string) {
        this.ajax.setStunToken(token);
    }

    async login(id: string, password: string): Promise<{ user: User, token: string }> {
        return this.ajax.post(`${this.url}/auth/user`, { id, password });
    }

    async getConnectedRemoteIDs(): Promise<string[]> {
        return this.ajax.get(`${this.url}/connected-remote-ids`);
    }

    async getClients(): Promise<{ [id: string]: Client }> {
        return this.ajax.get(`${this.url}/clients`);
    }

    async putClient(client: Client): Promise<Client> {
        return this.ajax.put(`${this.url}/clients/${client.id}`, client);
    }

    async getClient(id: string): Promise<Client> {
        return this.ajax.get(`${this.url}/clients/${id}`);
    }

    async deleteClient(id: string): Promise<void> {
        return this.ajax.del(`${this.url}/clients/${id}`);
    }

    async patchClient(client: Partial<Client> & { id: string }): Promise<void> {
        return this.ajax.patch(`${this.url}/clients/${client.id}`, client);
    }

    async getClientToken(id: string): Promise<string> {
        return this.ajax.post(`${this.url}/auth/client`, { id })
            .then(body => body.token);
    }

    async getRemotes(): Promise<{ [id: string]: Remote }> {
        return this.ajax.get(`${this.url}/remotes`);
    }

    async getRemote(id: string): Promise<Remote> {
        return this.ajax.get(`${this.url}/remotes/${id}`);
    }

    async putRemote(remote: Remote): Promise<Remote> {
        return this.ajax.put(`${this.url}/remotes/${remote.id}`, remote);
    }

    async deleteRemote(id: string): Promise<void> {
        return this.ajax.del(`${this.url}/remotes/${id}`);
    }

    async getRemoteToken(id: string): Promise<string> {
        return this.ajax.post(`${this.url}/auth/remote`, { id })
            .then(body => body.token);
    }

    async patchRemote(remote: Partial<Remote> & { id: string }): Promise<void> {
        return this.ajax.patch(`${this.url}/remotes/${remote.id}`, remote);
    }

    async getUsers(): Promise<{ [id: string]: User }> {
        return this.ajax.get(`${this.url}/users`);
    }

    async getUser(id: string): Promise<User> {
        return this.ajax.get(`${this.url}/users/${id}`);
    }

    async putUser(user: User & { password: string }): Promise<User> {
        return this.ajax.put(`${this.url}/users/${user.id}`, user);
    }

    async deleteUser(id: string): Promise<void> {
        return this.ajax.del(`${this.url}/users/${id}`)
    }

    async patchUser(user: PatchableUser): Promise<void> {
        return this.ajax.patch(`${this.url}/users/${user.id}`, user);
    }
}

export const api = new API();
