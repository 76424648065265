import jwtDecode from "jwt-decode";
import { state, emptyUser } from "./state";
import { User } from "./definitions";
import { api } from "./api/api";

class SessionManager {
    /** Session is '{ "token": string, "user": User }' */
    private session: string | null = localStorage.getItem("session");

    constructor() {
        this.checkForActiveSession();
    }

    private tokenIsActive(token: string) {
        const decoded: any = jwtDecode(token);
        return decoded.exp > (Date.now() / 1000);
    }

    private checkForActiveSession() {
        if (!this.session || this.session.length === 0) return this.logout();

        const { token, user } = JSON.parse(this.session);
        if (!this.tokenIsActive(token)) return this.logout();
        this.login(token, user);
    }

    login(token: string, user: User) {
        this.session = JSON.stringify({ token, user });
        localStorage.setItem("session", this.session);
        state.set({ token, user, loggedIn: true });
        api.setStunToken(token);
    }

    logout() {
        state.set({ token: "", user: emptyUser, loggedIn: false });
        localStorage.setItem("session", "");
        this.session = "";
    }
}

export const session = new SessionManager();
