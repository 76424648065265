import Vue from "vue";
import { state } from "../../state";
import { api } from "../../api/api";

export const RemoteAddModal = Vue.extend({
    template: require("./remote-add-modal.html"),

    data() {
        return {
            submitting: false,
            submitted: false,
            error: "",
            name: "",
            id: "",
            description: "",
            token: "",
            tags: ""
        }
    },

    methods: {
        submit() {
            if (this.submitting) return;
            this.submitting = true;

            const tags = this.tags.trim().split(" ").filter(tag => tag !== "");
            api.putRemote({
                id: this.id,
                name: this.name,
                description: this.description,
                created: Date.now(),
                modified: Date.now(),
                revokeTokensBefore: Date.now() / 1000,
                tags
            })
                .then(async (remote) => {
                    const remotes = state.get().remotes;
                    remotes[remote.id] = remote;
                    state.set({ remotes });
                    this.token = await api.getRemoteToken(remote.id);
                    this.submitted = true;
                })
                .catch((e: Error) => this.error = e.message)
                .then(() => this.submitting = false);
        },

        close() {
            this.$emit("close");
        }
    }
})
