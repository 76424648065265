import Vue from "vue";
import { Remote } from "../../definitions";
import { api } from "../../api/api";

export const ManageRemote = Vue.extend({
    template: require("./manage-remote.html"),
    data() {
        return {
            loading: true,
            fetchingToken: false,
            remote: {} as Partial<Remote>,
            tags: "",
            token: "",
            editingRemote: false,
        }
    },

    created() {
        this.getRemote();
    },

    methods: {
        getRemote() {
            this.loading = true;
            api.getRemote(this.$route.params.id)
                .then((remote: Remote) => {
                    this.remote = remote;
                    this.tags = remote.tags.join(" ");
                })
                .catch((e: Error) => console.log(e))
                .then(() => this.loading = false);
        },

        fetchToken() {
            if (this.fetchingToken) return;
            this.fetchingToken = true;

            api.getRemoteToken(this.$route.params.id)
                .then(token => {
                    this.token = token;
                    this.fetchingToken = false;
                })
                .catch((e: Error) => console.log(e));
        },

        revokePreviousTokens(): void {
            api.patchRemote({
                id: this.$route.params.id,
                revokeTokensBefore: Date.now() / 1000
            })
                .catch((e: Error) => console.log(e));
        },

        editRemote() {
            this.editingRemote = true;
        },

        cancelEdit() {
            this.getRemote();
            this.editingRemote = false;
        },

        saveRemote() {
            api.patchRemote({
                id: this.remote.id!,
                name: this.remote.name!,
                description: this.remote.description!,
                tags: this.tags.trim().split(" ").filter(tag => tag !== ""),
            })
                .then(() => {
                    this.editingRemote = false;
                    return this.getRemote();
                })
        },

        deleteRemote() {
            api.deleteRemote(this.$route.params.id)
                .then(() => this.$router.push("/"))
                .catch((e: Error) => console.log(e));
        }
    }
});
