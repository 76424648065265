import Vue from "vue";
import { RemoteBox, RemoteAddModal } from "../../components";
import { state } from "../../state";
import { api } from "../../api/api";

export const Remotes = Vue.extend({
    template: require("./remotes.html"),
    components: { RemoteBox, RemoteAddModal },
    data() { return { ...state.get(), remotesLoading: false, addRemote: false } },
    created() { state.updateVue(this); },
    mounted() {
        this.getRemotes();
    },

    computed: {
        hasNoRemotes(): boolean {
            return Object.keys(this.remotes).length <= 0;
        }
    },

    methods: {
        isOnline(remoteID: string): boolean {
            return this.connectedRemotes.some(id => id === remoteID);
        },

        getRemotes() {
            this.remotesLoading = true;
            Promise.all([
                api.getRemotes(),
                api.getConnectedRemoteIDs()
            ])
                .then(([remotes, connectedRemotes]) => state.set({ remotes, connectedRemotes }))
                .catch((e: Error) => console.log(e))
                .then(() => this.remotesLoading = false);
        },

        onRemoteAddModalClose() {
            this.addRemote = false;
            this.getRemotes();
        }
    }
});
