import Vue from "vue";
import { UserAddModal, AdminChangePasswordModal } from "../../components";
import { state } from "../../state";
import { api } from "../../api/api";

export const Admin = Vue.extend({
    template: require("./admin.html"),
    components: { UserAddModal, AdminChangePasswordModal },

    data() {
        return {
            ...state.get(),
            showAddUser: false,
            showChangePassword: false,
            changePasswordUserID: "",
            usersWithPendingOps: {} as { [userid: string]: boolean }
        }
    },

    created() {
        state.updateVue(this);
        api.getUsers()
            .then((users: any) => state.set({ users }))
            .catch((e: Error) => console.log(e));
    },

    methods: {
        deleteUser(id: string) {
            this.usersWithPendingOps[id] = true;
            api.deleteUser(id)
                .then(() => {
                    const users = state.get().users;
                    delete users[id];
                    state.set({ users });
                    delete this.usersWithPendingOps[id];
                })
                .catch((e: Error) => console.log(e));
        },

        changePassword(id: string) {
            this.changePasswordUserID = id;
            this.showChangePassword = true;
        },

        prettyDate(epoch: number) {
            const date = new Date(0);
            date.setUTCSeconds(epoch);
            return date.toLocaleDateString();
        }
    }
});
