import Vue from "vue";
import { state } from "../../state";
import { api } from "../../api/api";

export const ClientAddModal = Vue.extend({
    template: require("./client-add-modal.html"),

    data() {
        return {
            submitting: false,
            submitted: false,
            error: "",
            name: "",
            id: "",
            description: "",
            token: "",
        }
    },

    methods: {
        submit() {
            if (this.submitting) return;
            this.submitting = true;

            api.putClient({
                id: this.id,
                name: this.name,
                description: this.description,
                revokeTokensBefore: Date.now(),
                created: Date.now(),
                owner: state.get().user.id,
                modified: Date.now()
            })
                .then(async (client) => {
                    const clients = state.get().clients;
                    clients[client.id] = client;
                    state.set({ clients });
                    this.token = await api.getClientToken(client.id);
                    this.submitted = true;
                })
                .catch((e: Error) => this.error = e.message)
                .then(() => this.submitting = false);
        },

        close() {
            this.$emit("close");
        }
    }
})
