import Vue from "vue";
import { state } from "../../state";
import { session } from "../../session-manager";

export const Navbar = Vue.extend({
    template: require("./navbar.html"),
    data: () => state.get(),
    created() { state.updateVue(this); },

    methods: {
        signOut(): void {
            session.logout();
        }
    }
});
