import Vue from "vue";
import { api } from "../../api/api";

export const AdminChangePasswordModal = Vue.extend({
    template: require("./admin-change-password-modal.html"),
    props: {
        userid: String,
    },

    data() {
        return { password: "", submitted: false, submitting: false, error: "" }
    },

    methods: {
        submit() {
            if (this.submitting) return;
            this.error = "";
            this.submitting = true;
            api.patchUser({ id: this.userid, password: this.password })
                .then(() => this.submitted = true)
                .catch((e: Error) => this.error = e.message)
                .then(() => this.submitting = false);

        },

        close() {
            this.$emit("close");
        }
    }
});
