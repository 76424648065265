import VueRouter from "vue-router";
import { Login, Remotes, ManageRemote, Clients, ManageClient, Admin, MyAccount } from "./pages";
import { state } from "./state";

export const router = new VueRouter({
    linkActiveClass: "is-active",
    routes: [
        { path: "/", redirect: "/remotes" },
        { path: "/admin", component: Admin },
        { path: "/clients", component: Clients },
        { path: "/remotes", component: Remotes },
        { path: "/login", component: Login },
        { name: "manage-remote", path: "/manage-remote/:id", component: ManageRemote },
        { name: "manage-client", path: "/manage-client/:id", component: ManageClient },
        { name: "my-account", path: "/my-account", component: MyAccount },
    ]
});

router.beforeEach((to, _from, next) => {
    if (!state.get().loggedIn && to.path !== "/login") {
        return next("/login");
    }

    next();
});

state.on("Update", ({ loggedIn }) => {
    if (loggedIn) return;
    router.push("/login");
})
