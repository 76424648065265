export class Ajax {
    private readonly jsonHeader = new Headers({ "Content-Type": "application/json" });
    private readonly plainHeader = new Headers();

    private parseResponse(res: Response): Promise<string | any> {
        const type = res.headers.get("content-type");
        if (type && type.includes("application/json")) {
            return res.json();
        }

        return res.text();
    }

    private async statusRej<T = void>(res: Response): Promise<T> {
        const result = await this.parseResponse(res);
        if (typeof result === "string") {
            throw new Error(result);
        }

        throw new Error(result.error);
    }

    setStunToken(token: string) {
        this.jsonHeader.set("stun-token", token);
        this.plainHeader.set("stun-token", token);
    }

    async get<T = any>(uri: string): Promise<T> {
        const req = new Request(encodeURI(uri), { headers: this.plainHeader });
        const res = await fetch(req);
        if (res.status !== 200) return this.statusRej(res);
        return this.parseResponse(res);
    }

    async put<T = any>(uri: string, body: any): Promise<T> {
        const req = new Request(encodeURI(uri), {
            method: "PUT", body: JSON.stringify(body), headers: this.jsonHeader
        });
        const res = await fetch(req);
        if (res.status !== 200) return this.statusRej(res);
        return this.parseResponse(res);
    }

    async patch<T = any>(uri: string, body: any): Promise<T> {
        const req = new Request(encodeURI(uri), {
            method: "PATCH", body: JSON.stringify(body), headers: this.jsonHeader
        });
        const res = await fetch(req);
        if (res.status !== 200) return this.statusRej(res);
        return this.parseResponse(res);
    }

    async del(uri: string): Promise<void> {
        const req = new Request(encodeURI(uri), { method: "DELETE", headers: this.plainHeader });
        const res = await fetch(req);
        if (res.status !== 200) return this.statusRej(res);
        return this.parseResponse(res);
    }

    async post<T = any>(uri: string, body?: any): Promise<T> {
        const req = new Request(encodeURI(uri), {
            method: "POST", body: JSON.stringify(body), headers: this.jsonHeader
        });
        const res = await fetch(req);
        if (res.status !== 200) return this.statusRej(res);
        return this.parseResponse(res);
    }
}
