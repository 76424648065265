import Vue from "vue";
import { state } from "../../state";
import { api } from "../../api/api";
import { session } from "../../session-manager";

export const MyAccount = Vue.extend({
    template: require("./my-account.html"),

    data() {
        console.log(state.get().user);
        return {
            ...state.get(),
            newPassword: "",
            confirmNewPassword: "",
            submittingNewPassword: false,
            newPasswordSubmitted: false,
            passwordChangeError: "",
            deleteUserError: "",
            deletingUser: false,
        }
    },

    created() {
        state.updateVue(this);
    },

    methods: {
        submitNewPassword(): void {
            this.passwordChangeError = "";
            this.newPasswordSubmitted = false;
            if (this.submittingNewPassword) return;
            this.submittingNewPassword = true;

            if (this.confirmNewPassword !== this.newPassword) {
                this.passwordChangeError = "Passwords do not match.";
                this.submittingNewPassword = false;
                return;
            }

            api.patchUser({
                id: this.user.id,
                password: this.newPassword
            })
                .then(() => this.newPasswordSubmitted = true)
                .catch((e: Error) => this.passwordChangeError = e.message)
                .then(() => this.submittingNewPassword = false);
        },

        deleteUser(): void {
            this.deleteUserError = "";
            if (this.deletingUser) return;
            this.deletingUser = true;

            api.deleteUser(this.user.id)
                .then(() => session.logout())
                .catch((e: Error) => this.deleteUserError = e.message)
                .then(() => this.deletingUser = false);
        }
    }
});
