import Vue from "vue";
import { state } from "../../state";
import { User } from "../../definitions";
import { api } from "../../api/api";
import { session } from "../../session-manager";

export const LoginBox = Vue.extend({
    template: require("./login.html"),
    data() {
        return {
            ...state.get(),
            username: "",
            password: "",
            error: "",
            loading: false,
        }
    },

    created() {
        state.updateVue(this);
    },

    methods: {
        submit() {
            this.error = "";
            if (this.loading) return;
            this.loading = true;
            api.login(this.username, this.password)
                .then(({ token, user }: { token: string, user: User }) => {
                    session.login(token, user);
                    this.$router.push("/");
                })
                .catch((e: Error) => this.error = e.message)
                .then(() => this.loading = false);
        }
    }
});
