import Vue from "vue";
import { Client } from "../../definitions";
import { api } from "../../api/api";

export const ManageClient = Vue.extend({
    template: require("./manage-client.html"),
    data() {
        return {
            loading: true,
            fetchingToken: false,
            client: {} as Partial<Client>,
            token: "",
        }
    },
    created() {
        api.getClient(this.$route.params.id)
            .then((client: Client) => {
                this.client = client;
                this.loading = false;
            })
            .catch((e: Error) => console.log(e));
    },

    methods: {
        fetchToken() {
            if (this.fetchingToken) return;
            this.fetchingToken = true;

            api.getClientToken(this.$route.params.id)
                .then(token => {
                    this.token = token;
                    this.fetchingToken = false;
                })
                .catch((e: Error) => console.log(e));
        },

        revokePreviousTokens() {
            api.patchClient({
                id: this.$route.params.id,
                revokeTokensBefore: Date.now() / 1000
            })
                .catch((e: Error) => console.log(e));
        },

        deleteClient() {
            api.deleteClient(this.$route.params.id)
                .then(() => this.$router.push("/clients"))
                .catch((e: Error) => console.log(e));
        }
    }
});
