import Vue, { PropType } from "vue";
import { Remote } from "../../definitions";
import { state } from "../../state";

export const RemoteBox = Vue.extend({
    template: require("./remote.html"),
    props: {
        remote: Object as PropType<Remote>,
        isOnline: Boolean
    },
    data() { return state.get(); },
    created() { state.updateVue(this); }
});
