import Vue from "vue";
import { ClientBox, ClientAddModal } from "../../components";
import { state } from "../../state";
import { api } from "../../api/api";

export const Clients = Vue.extend({
    template: require("./clients.html"),
    components: { ClientBox, ClientAddModal },
    data() { return { ...state.get(), clientsLoading: false, addClient: false } },
    created(): void { state.updateVue(this); },
    mounted(): void {
        this.getClients();
    },

    computed: {
        hasNoClients(): boolean {
            return Object.keys(this.clients).length <= 0;
        },
    },

    methods: {
        getClients(): void {
            this.clientsLoading = true;
            api.getClients()
                .then(clients => state.set({ clients }))
                .catch((e: Error) => console.log(e))
                .then(() => this.clientsLoading = false);
        },

        onClientAddModalClose(): void {
            this.addClient = false;
            this.getClients();
        }
    }
});
