import Vue from "vue";
import { state } from "../../state";
import { api } from "../../api/api";

export const UserAddModal = Vue.extend({
    template: require("./user-add-modal.html"),

    data() {
        return {
            submitting: false,
            submitted: false,
            error: "",
            id: "",
            bio: "",
            email: "",
            password: "",
            role: "user" as ("user" | "admin")
        }
    },

    methods: {
        submit() {
            if (this.submitting) return;
            this.submitting = true;

            api.putUser({
                id: this.id,
                email: this.email,
                password: this.password,
                role: this.role,
                bio: this.bio,
                created: Date.now(),
                modified: Date.now()
            })
                .then(user => {
                    const users = state.get().users;
                    users[user.id] = user;
                    state.set({ users });
                    this.submitted = true;
                })
                .catch((e: Error) => this.error = e.message)
                .then(() => this.submitting = false);
        },

        close() {
            this.$emit("close");
        }
    }
})
