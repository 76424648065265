import { State } from "./helpers";
import { Remote, User, Client } from "./definitions";

export const emptyUser: User = {
    id: "",
    bio: "",
    email: "",
    role: "user",
    created: Date.now(),
    modified: Date.now()
};

export const state = new State({
    loggedIn: false,
    token: "",
    user: emptyUser,
    remotes: {} as { [id: string]: Remote },
    clients: {} as { [id: string]: Client },
    connectedRemotes: [] as string[],
    users: {} as { [id: string]: User }
});
